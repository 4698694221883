import * as Sentry from "@sentry/browser";
import "isomorphic-fetch";

export const get = (url) => {
    const options = {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          "x-api-key": process.env.API_KEY
        }
      };

    return fetch(url,options).catch(err => Sentry.captureException(err));
}

export const post = async (url,body) => {
  try {
    const options = {
        method: 'POST',

        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          "x-api-key": process.env.API_KEY
        },
        body: body,
      };

    const result = await fetch(url,options);
    return result;
  } catch (err) {
    Sentry.captureException(err);
  }
}