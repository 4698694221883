import config from "./config";

export const sentryOptions = {
  release: config.semver,
  environment: config.deployment_env,
  dsn: config.sentry_dsn,
  ignoreErrors: [
    "ResizeObserver loop limit exceeded","offsetWidth",
    // This error means that ResizeObserver wasn't able to deliver all observations within a single animation frame. It is benign (site won't break)
    "ResizeObserver loop completed with undelivered notifications",
    "TypeError: Failed to fetch",
    "TypeError: NetworkError when attempting to fetch resource", //Firefox"
    "TypeError: cancelled", //Safari
    "TypeError: cancelado",
    "TypeError: annulé",
    "TypeError: visszavonva",
    "TypeError: 已取消",
    "TypeError: Type error",
    "ResizeObserver loop limit exceeded", "offsetWidth",
    "TypeError: Load failed",
    "Network request failed",
    "tgetT is not defined",
    "NetworkError",
    "网络连接已中断",
    /Loading chunk \d+ failed. *\n*\(timeout:/g,
    /Network Error/g,
    /timeout/g,
    "Se agotó el tiempo de espera",
    /The request timed out/g,
    /Request aborted/g,
    /ResizeObserver loop completed with undelivered notifications/g,
    "Failed to read the 'localStorage' property from 'Window'",
  ],
};
