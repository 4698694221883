
const searchCalculatorParamsToArray = (urlParams) => {
    const pax = urlParams.get("pax") || urlParams.get("PAX");
    const currency = urlParams.get("currency") || urlParams.get("CURRENCY");
    const flightParams = urlParams.getAll("flight").map(p => p.toUpperCase());
    const country = urlParams.get("country");
    const destination = urlParams.get("destination");

    // validate data before processing it
    if (!pax || flightParams.length < 1
        || flightParams.some(p => !/^[A-Z]{3}-[A-Z]{3}-(Economy|Premium_Economy|Business|First)$/i.test(p)))
        return {country, destination};

    if (currency && currency.length !== 3){
        return {country, destination};
    }

    return { pax, flightParams, currency, country, destination };
};

export { searchCalculatorParamsToArray }