import './Accordian.css'
import React, { useState } from 'react';

const Accordian = ({ title, children }) => {

  const [opened, setOpened] = useState(false);
  
    return (
      <div {...{ className: `accordion-item ${opened && 'accordion-item--opened'}`, onClick: () => setOpened(!opened)}}>
          <div {...{ className: 'accordion-item__line' }}>
            <h5 {...{ className: 'accordion-item__title' }}>
              {title}
            </h5>
            <span {...{ className: 'accordion-item__icon' }}/>
          </div>
            <div {...{ className: 'accordion-item__inner' }}>
              <div {...{ className: 'accordion-item__content' }}>
                <p {...{ className: 'accordion-item__paragraph' }}>
                  {children}
                </p>
              </div>
            </div>
        </div>
    )
};


export default Accordian;
