import React from 'react';
import "./Modal.css";

const Modal = ({ handleClose, small, show, children }) => {
  const showHideClassName = show ? "modal modal-show" : "modal modal-hide";
  const mainClassName = small ? "modal-main modal-sm" : "modal-main";
  
  return <>
    <div className={showHideClassName}>
      <section className={mainClassName}>
        {children}
        <button className="modal-close" onClick={handleClose}>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16" fill="none">
            <path d="M2 2L8 8M14 14L8 8M8 8L14 2M8 8L2 14" stroke="black" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </button>
      </section>
    </div>
  </>
};

export default Modal;