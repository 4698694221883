import React from "react";
import SingleProject from "./Project";
import PropTypes from "prop-types";

const ProjectsList = ({ projects, title, numProjectsToShow, showLearnMore = true, lang }) => {

    const projectsToDisplay = projects && projects.length ? projects
        .filter((a) => {
            if (!a.name) {
                return;
            }
            return !a.name.toLowerCase().includes("sasaboe");
        })
        .sort((a, b) => a.rank - b.rank)
    : [];

    return (
        <>
            <section className="projects-baskets-section">
                {
                    projectsToDisplay ? projectsToDisplay.map((project) => {
                        return <div key={project.id || shortDescription}>
                            <SingleProject project={project} showLearnMore={showLearnMore} lang={lang} />
                        </div>
                    }) : ''
                }
            </section>
        </>
    )
};

ProjectsList.propTypes = {
    title: PropTypes.string,
    projects: PropTypes.array,
    numProjectsToShow: PropTypes.number
};

export default ProjectsList;