export const KILOGRAM_FACTOR = 1000;
export const POUND_FACTOR = 2204.6226218;
export const UNIT_KILOGRAM_SYMBOL = 'kg'
export const UNIT_POUND_SYMBOL = 'lb'
export const UNIT_TONNE_SYMBOL = 't'
export const KM_FACTOR = 1;
export const MILES_FACTOR = 0.621371;

export const convertToTonne = (value, fromUnit) => {
    const symbol = fromUnit.toLowerCase();
    if (symbol === UNIT_TONNE_SYMBOL) return value;

    if (symbol === UNIT_KILOGRAM_SYMBOL) return value / KILOGRAM_FACTOR;
    else if (symbol === UNIT_POUND_SYMBOL) return value / POUND_FACTOR;
}

export const convertToKG = (value, fromUnit) => {
    const symbol = fromUnit.toLowerCase();
    if (symbol === UNIT_KILOGRAM_SYMBOL) return value;

    if (symbol === UNIT_TONNE_SYMBOL) return value * KILOGRAM_FACTOR;
    else if (symbol === UNIT_POUND_SYMBOL) return (convertToTonne(value, fromUnit) * KILOGRAM_FACTOR);
}

export const convertToMilesFromKm = (kmValue) => {
    return {
        value: kmValue * MILES_FACTOR,
        unit: 'mile'
    };
}

export const convertToKmFromMiles = (milesValue) => {
    return {
        value: milesValue / MILES_FACTOR,
        unit: 'km'
    };
}

export const getImperialDistanceUnit = (unitTypeWeight) => {
    return unitTypeWeight === 'lb' ? 'Miles' : 'Km';
}

export const formatDistanceUnit = (unitValue) => {
    if (unitValue.toLowerCase() === 'miles')
        return 'mi.';
    if (unitValue.toLowerCase() === 'km')
        return 'km';
}

export const formatDistanceUnitCapitilizeFirstLetter = (unitValue) => {
    if (unitValue.toLowerCase() === 'miles')
        return 'Miles';
    if (unitValue.toLowerCase() === 'km')
        return 'Km';
}

export const formatWeightUnitCapitilizeFirstLetter = (unitValue) => {
    if (unitValue.toLowerCase() === 'lb')
        return 'Lb';
    if (unitValue.toLowerCase() === 'kg')
        return 'Kg';
}

export const formatWeightUnit = (unitValue) => {
    if (unitValue.toLowerCase() === 'lb' || unitValue.toLowerCase() === 'lbs' || unitValue.toLowerCase()  === 'pounds')
        return 'lb';
    if (unitValue.toLowerCase() === 'kg' || unitValue.toLowerCase() === 'kilograms')
        return 'kg';
}

export const getFlightSuffix = (unit) => {
    if (unit == "km") {
        return "kms";
    }
    return unit;
};