import React from "react";
import PropTypes from "prop-types";
import { getLinkToProject } from "../../util/pathUtils";

const SingleProject = ({ project, showLearnMore, lang }) => {
    const linkToProject = getLinkToProject(project.code, lang);

    return <div className="project-basket mb-4">
        <div className="row">
            <div className="col-6">
                <div className="h-240px w-100 img-cover" style={{ backgroundImage: `url(${project.thumbnailImageUrl})` }}>

                </div>
            </div>
            <div className="col-6 py-3">
                <h4 className="font-bold">{project.name}</h4>
                <h5>{project.locationDescription}</h5>
                <p>{project.shortDescription}</p>
                {showLearnMore &&
                    <a href={linkToProject} className="font-bold color-primary">Learn More</a>
                }
            </div>
        </div>
    </div>
}

SingleProject.propTypes = {
    project: PropTypes.object,
    showLearnMore: PropTypes.bool
}

export default SingleProject;