import {get,post} from './carbonApi';

const BASE_URL = process.env.API_URL +   "/projects/v1";

export const projects = (countryCode, merchantCode, locale) => {

if(merchantCode == null){
    merchantCode = "CARBONCLICK";
}
    let queryArray = [];
    if (locale) queryArray.push("locale=" + locale);
    if (countryCode) queryArray.push("countryCode=" + countryCode);
    if (merchantCode) queryArray.push("merchantCode=" + merchantCode );
    let queryString = queryArray.length ? '?'+queryArray.join("&") : "";

    return get(`${BASE_URL}/${queryString}`);
}