import {useRouteMatch} from "react-router-dom";

const acquireBasePath = () => location.protocol + '//' + location.host;

const getLinkToProject = (code, lang) => {
  const langFromURL = lang ? `?lang=${lang}` : '';
  return `https://my.carbon.click/projects/${code}${langFromURL}`;
}


const getMyCCBaseUrlWithMerchantCode = (merchantCode) =>
    `${process.env.MY_CC_URL}/RM-${merchantCode}`;

export {
  acquireBasePath, getLinkToProject, getMyCCBaseUrlWithMerchantCode
}