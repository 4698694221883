import React from 'react';
import "./popover.css";

const Popover = ({ content, children, popoverStyles }) => {

  return <>
    <span className="popover-wrapper">
        {children}
        <span style={popoverStyles} className="translate-dynamic popover">
            {content}
        </span>
    </span>
  </>
};

export default Popover;