/*
These are mostly pulled from the .gitlab-ci.yml or other GitLab environment variables. 
WebPack will replace these at build time.
In local build, the defaults and .env files will be used.
*/

module.exports = {
  port: process.env.PORT || 1112,
  semver: "1.0.",
  deployment_env: process.env.CI_ENVIRONMENT_NAME || "local",
  sentry_dsn: process.env.SENTRY_DSN,
};
