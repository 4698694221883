const footerLinks = {
  myCarbonClick: "https://my.carbon.click",
  carbonclick: "https://carbonclick.com/",
  logInToMyCC: "https://my.carbon.click",
  howItWorks: "https://carbonclick.com/business",
  aboutCarbonClick: "https://www.carbonclick.com/about/",
  faq: "https://www.carbonclick.com/faqs",
  termsAndConditions: "https://www.carbonclick.com/terms/",
  privacyPolicy: "https://www.carbonclick.com/privacy-policy/",
  contactUs: "https://www.carbonclick.com/contact-us/",
  news: "https://www.carbonclick.com/news/",
  personalFootprintCalculator: "https://www.carbonclick.com/personal-footprint-calculator/",
  purchaseCarbonOffsets: "https://www.carbonclick.com/purchase-carbon-offsets/",
  airport: "https://www.carbonclick.com/business/airport/",
  airlines: "https://www.carbonclick.com/business/airlines/",
  ecommerce: "https://www.carbonclick.com/business/ecommerce/",
  projects: "https://my.carbon.click/baskets?_ga=2.172208163.1491074222.1653969361-27212499.1635162001",

  facebook: "https://www.facebook.com/CarbonClickCommunity/",
  instagram: "https://www.instagram.com/carbon.click/",
  twitter: "https://twitter.com/carbonclick",
  linkedin: "https://www.linkedin.com/company/carbonclick/",
  medium: "https://medium.com/carbonclick/latest",
};

export { footerLinks };
