import {get,post} from './carbonApi';

const BASE_URL = process.env.API_URL +   "/impacts/v1";

export const flights = () => {
    return get(`${BASE_URL}/flights`);
}

export const all = (locale) => {
    return get(`${BASE_URL}/all?locale=${locale}`);
}

export const merchants = (code, locale, fields) => {
    let queryArray = [];
    if (locale) queryArray.push("locale=" + locale);
    if (fields){
        fields.map((field) => {
            queryArray.push("fields=" + field);
        })
        
    } 
    let queryString = queryArray.length ? '?' + queryArray.join("&") : "";

    return get(`${BASE_URL}/merchants/${code}${queryString}`, {
        headers: { "x-api-key": `${process.env.API_KEY}` }
    });
}
