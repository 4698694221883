import {retryWeglotInitializing} from "../../util/WeglotInitializeUtil";
import 'url-search-params-polyfill';
const FlightCalculatorWidgetEmbedded = React.lazy(() => import("@carbonclick/flight-calculator-library/dist/flight-calculator-lib").catch(document.location.reload.bind(document.location)));
import React, {useEffect, useState} from 'react';
import {searchCalculatorParamsToArray} from "../../util/flightUrlParamParser";

const config = {
    API_KEY: process.env.API_KEY, 
    API_URL: process.env.API_URL,
    STRIPE_CLIENT_ID: process.env.STRIPE_CLIENT_ID,
    MYCC_BASE_URL: process.env.MY_CC_URL,
    HOTJAR_SITE_ID: '2265907'
}

const FlightCalculatorV2 = ({programmeCode, merchantCode, transactionMerchantCode, currency, onChangeCurrency, receiptEmailAddress, checked}) => {
    const urlData = searchCalculatorParamsToArray(new URLSearchParams(window.location.search));

    const[langFromWeglot,setLangFromWeglot] = useState();

    useEffect(() => {
        retryWeglotInitializing(50, (lang) => {setLangFromWeglot(lang)})
    }, []);

    return (
      <React.StrictMode>
        <div className={"w-100 h-100 bg-white translate-dynamic"} >
          <React.Suspense fallback={<div></div>}>
            <FlightCalculatorWidgetEmbedded
              programmeCode={programmeCode}
              config={config}
              flightStrings={urlData?.flightParams}
              currency={urlData?.currency || currency}
              pax={urlData?.pax}
              merchantCode={merchantCode}
              transactionMerchantCode={transactionMerchantCode}
              country={urlData.country}
              destination={urlData.destination}
              language={langFromWeglot}
              onChangeCurrency={onChangeCurrency}
              receiptEmailAddress={receiptEmailAddress}
              checked={checked}
            />
          </React.Suspense>
        </div>
      </React.StrictMode>
    );
};

export default React.memo(FlightCalculatorV2);
