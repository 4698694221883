export async function retryWeglotInitializing(interval, updateLanguageCallback, maxAttemptCount = 100) {
    let attempt = 0;
    let weglotIsInitialized = false;
    while (attempt < maxAttemptCount) {
        await timeout(interval);
        weglotIsInitialized = initWeglot(updateLanguageCallback)
        if (weglotIsInitialized) {
            return;
        }
        attempt++;
    }
    console.error("failed to initialize weglot, max attempt count exceeded")
}

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function initWeglot(updateLanguageCallback) {
    if (window['Weglot']) {
        if(Weglot.initialized){
            updateLanguageCallback(Weglot.getCurrentLang());
        }
        else{
            Weglot.on("initialized", ()=>{updateLanguageCallback(Weglot.getCurrentLang())});
        }
        Weglot.on("languageChanged", (newLang,oldLang)=>{updateLanguageCallback(newLang)});
        return true;
    }
    return false;
}


