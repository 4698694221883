import 'url-search-params-polyfill';
import config from "../../../config";
import TagManager from "react-gtm-module";

export const initialiseGoogleAnalyticsWithCustomerProdTrackingId = (merchantCode, customerProdGoogleAnalyticsTagManager) =>{
    //Not entirely sure why I needed this, but got it working (including additiona params); see https://developers.google.com/analytics/devguides/migration/ecommerce/gtm-ga4-to-ua
    window.dataLayer=[];
    function gtag() {window.dataLayer?.push(arguments);}
    window.gtag = gtag;
    gtag('set', {merchant_code: merchantCode});

    const googleTagManagers = [{id: process.env.GTM_ID, preview:process.env.GTM_PREVIEW, auth: process.env.GTM_AUTH}];

    const isProductionEnv = config.deployment_env === "prod";

    if(isProductionEnv && customerProdGoogleAnalyticsTagManager){
        googleTagManagers.push({id: customerProdGoogleAnalyticsTagManager});
    }
    for (const tagManager of googleTagManagers) {
        if (tagManager) {
            TagManager.initialize({ gtmId: tagManager.id, auth: tagManager.auth, preview: tagManager.preview });
        }
    }
}

export const trackAdditionalPageView = (pageTitle) => {
    window.gtag('event',"page_view",{page_title:pageTitle})
}

export const trackEvent = (name, parameters) => {
    window.gtag('event', name, parameters);
}