import React, { useState, useEffect } from 'react';
import './climate-friendly-traveller.css'
import "../common/css/localeSelector.css";
import Accordian from '../common/components/Accordian/Accordian.js'
import logo from "../common/media/cc_logo_white.svg";
import ProjectsList from "../common/components/Projects/ProjectsList";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from "react-router-dom";
import CountrySelect from "../common/components/CountrySelectionModal.js";
import Popover from "../common/components/popover/popover.js";
import Footer from "../common/components/Footer";
import FlightCalculator from "../common/components/offset/FlightCalculatorV2";
import { formatNumber } from "../common/util/numberFormatter";
import { currencyList } from "../common/components/LocaleSelector/currencyConfig";
import { footerLinks } from '../common/util/uiConfigs/footerConfiguration.js';


const ClimateFriendlyTravellerPage = ({
    merchantCode,
    merchantCodeFromUrl,
    projects,
    flightsImpact,
    selectedCurrency,
    onChangeCurrency,
    currentLang,
}) => {
    const [country, setCountry] = useState();
    const [isShowModal, setShowModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState({});
    const history = useHistory();
    
    const treeGrowthYears = flightsImpact && !flightsImpact.error && formatNumber(flightsImpact.treeGrowthYears?.value);
    const treeMetric = treeGrowthYears && <span className="translate-dynamic"><span>An average tree captures about 20kg of carbon dioxide per year, and about 1 tonne over its lifetime. By funding projects that offset carbon emissions, we are having the same impact as </span> <span className="no-translate"> {treeGrowthYears}</span> <span>trees have in one year.</span></span>
    
    const planChosen = () => {
        history.push("subscribe/payment");
    };

    const GetFlightSuffix = (unit) => {
        if (unit == "km") {
            return "kms";
        }
        return unit;
    };

    const carbonOffsetImpactValue = flightsImpact && !flightsImpact.error && Math.round(flightsImpact.carbonOffsetImpact?.value).toLocaleString();
    const treeGrowthYearsValue = flightsImpact && !flightsImpact.error && flightsImpact.treeGrowthYears?.value.toLocaleString();
    const passengerDistanceValue = flightsImpact && !flightsImpact.error && Math.round(flightsImpact.passengerDistance?.value).toLocaleString();
    const passengerDistanceUnit = flightsImpact && !flightsImpact.error && GetFlightSuffix(flightsImpact.passengerDistance?.unit.toLowerCase());
    const carbonOffsetImpactUnit = flightsImpact && !flightsImpact.error && flightsImpact.carbonOffsetImpact?.unit.toLowerCase();
    const numberOfPassengersValue = flightsImpact && !flightsImpact.error && (flightsImpact.numberOfPassengers?.value)?.toLocaleString();

    useEffect(() => {
        if (country) {
            //impactApi.flights();
            //props.actions.loadProjects(country, props.merchantCode);
            // props.actions.getAllFlightsImpact(`en_${country}`);
        }
    }, [country]);

    useEffect(() => {
        //trackAdditionalPageView('/',['aviation','customer'])
    }, []
    );

    return (
        <div>
            <div className="h-85vh w-100 position-relative img-cover CFT-hero-img vertical-align-parent" >
                {/*<div className="position-absolute top-abs w-100 px-3 pt-2">
                    <p className="color-white text-shadow">{`Looks like you’re in ${country}.`}
                        <br />
                        <a onClick={() => setShowModal(true)} className='cursor-pointer font-bold hover color-white'>Change location</a>
                    </p>
                </div>*/}
                <div className="container color-white text-align-start vertical-align">
                    <div className="mobile-hide">
                        <div className="mw-720px">
                            <h1 className="mb-4 text-shadow mt-0 color-white">Be a Climate Friendly Traveller</h1>
                            <h3 className="mb-3 text-shadow color-white">Offset your flight’s carbon footprint</h3>
                            <div className="mobile-hide mb-4">
                                <a className="btn btn-primary btn-lg" href="#FlightCalculator">Offset My Flight</a>
                            </div>
                        </div>

                        <div className="d-flex">
                            <h4 className="mr-2 mt-0 color-white">Powered By</h4>
                            <div>
                                <img style={{ height: 30 }} src={logo} />
                            </div>
                        </div>
                    </div>
                    <div className="mobile-show text-center">
                        <h1 className="mb-4 text-shadow mt-0 color-white">Be a Climate Friendly Traveller!</h1>
                        <a className="btn btn-primary btn-lg font-bold" href="#FlightCalculatorDummy">Offset My Flight</a>
                    </div>
                </div>

                <div className="position-absolute bottom-abs w-100 pb-2 h-80px">
                    <a href="#content"><div className="arrow arrow-first"></div></a>
                </div>
            </div>
            <div className="mobile-show">
                <div className="bg-dark p-3 text-center">
                    <div className="d-flex w-fit center color-white logo-container">
                        <h5 className="mr-2 font-bold mt-1 mb-0 color-white">Powered By</h5>
                        <div>
                            <img style={{ height: 30 }} src={logo} />
                        </div>
                    </div>
                </div>
            </div>

            <div id="content" className="py-6">
                <div className="container text-center">
                    <div className="mb-3 center mw-600px">
                        <div className="mb-3 mobile-show">
                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="59" viewBox="0 0 60 59" fill="none">
                                <path d="M47.1028 3.66441C44.8181 5.94684 41.457 9.63988 38.1152 13.4034L24.9992 16.968C24.8866 16.9987 24.7866 17.0623 24.7127 17.1521L21.1129 21.4647C20.9708 21.634 20.9413 21.8693 21.0345 22.0694C21.1277 22.2694 21.3289 22.3967 21.5494 22.3967H28.7479C29.0616 22.3967 29.3162 22.141 29.3162 21.8284C29.3162 21.5147 29.0616 21.2601 28.7479 21.2601H22.7633L25.4697 18.016L36.7398 14.9538C32.0442 20.2712 27.6863 25.3589 26.7826 26.4194H15.109C14.9476 26.4194 14.793 26.4887 14.685 26.6092L11.4648 30.2079C11.333 30.358 11.2875 30.5626 11.3443 30.7524C11.4012 30.9422 11.5535 31.0877 11.7445 31.14L22.0972 33.8419L26.1517 42.3896C26.2302 42.5556 26.3859 42.6738 26.5689 42.7045C26.6007 42.7113 26.6337 42.7136 26.6655 42.7136C26.8144 42.7136 26.9599 42.6533 27.0679 42.5476L31.9931 37.6224C32.0977 37.5179 32.1568 37.3803 32.159 37.2337L32.3409 29.9306L40.4204 23.8255L42.1981 39.1808C42.222 39.3865 42.3584 39.5639 42.5516 39.6423C42.7426 39.7162 42.962 39.6866 43.1245 39.5559L47.4825 36.0061C47.6018 35.9061 47.678 35.7617 47.6905 35.6048L49.1898 14.7378L58.5116 5.41601C58.6957 5.23074 58.7287 4.94316 58.5934 4.72037C58.5025 4.56579 56.2667 0.947763 52.5884 0.947763C50.7527 0.944353 48.9056 1.85937 47.1028 3.66441ZM57.3761 4.94657L48.2384 14.0843C48.1429 14.1809 48.0804 14.3093 48.0724 14.4457L46.5766 35.2763L43.2132 38.018L41.4388 22.707C41.415 22.5047 41.2831 22.3297 41.0944 22.2512C40.9103 22.1694 40.692 22.1955 40.5306 22.3206L31.4373 29.1895C31.2997 29.294 31.2156 29.4555 31.2111 29.6294L31.0292 36.9802L26.8326 41.1745L23.01 33.1178C22.9384 32.9655 22.8031 32.8552 22.6406 32.8098L12.9311 30.2761L15.3625 27.5595H27.0429C27.2089 27.5595 27.3668 27.4867 27.4748 27.3583C27.6158 27.1923 41.74 10.6333 47.9053 4.46917C49.4899 2.88579 51.0641 2.0833 52.5918 2.0833C55.005 2.08102 56.752 4.10202 57.3761 4.94657Z" fill="#2AA43C" stroke="#2AA43C"></path>
                                <path d="M23.9877 44.773L14.5158 54.2449C14.2942 54.4654 14.2942 54.8269 14.5158 55.0485C14.6272 55.1599 14.7727 55.2145 14.9182 55.2145C15.0637 55.2145 15.2092 55.1588 15.3206 55.0485L24.7925 45.5767C25.0141 45.3562 25.0141 44.9947 24.7925 44.773C24.572 44.5503 24.2094 44.5503 23.9877 44.773Z" fill="#2AA43C" stroke="#2AA43C"></path><path d="M17.3574 40.2743L3.07737 54.5543C2.85572 54.7748 2.85572 55.1374 3.07737 55.3568C3.18876 55.4704 3.33426 55.5227 3.47975 55.5227C3.62525 55.5227 3.77074 55.467 3.88213 55.3568L18.161 41.0768C18.3826 40.8563 18.3826 40.4937 18.161 40.2732C17.9405 40.0526 17.579 40.0526 17.3574 40.2743Z" fill="#2AA43C" stroke="#2AA43C"></path><path d="M2.29506 48.3719L13.0457 37.6212C13.2673 37.3996 13.2673 37.0381 13.0457 36.8176C12.824 36.596 12.4637 36.596 12.2421 36.8176L1.49143 47.5682C1.26978 47.7899 1.26978 48.1513 1.49143 48.3719C1.60283 48.4844 1.74832 48.5378 1.89381 48.5378C2.03931 48.5378 2.18367 48.4821 2.29506 48.3719Z" fill="#2AA43C" stroke="#2AA43C"></path><path d="M25.2366 57.4936L40.6521 42.0769C40.8727 41.8564 40.8727 41.4938 40.6521 41.2733C40.4328 41.0528 40.069 41.0528 39.8485 41.2733L24.4319 56.6911C24.2102 56.9116 24.2102 57.2742 24.4319 57.4936C24.5433 57.6072 24.6888 57.6595 24.8342 57.6595C24.9797 57.6595 25.1252 57.605 25.2366 57.4936Z" fill="#2AA43C" stroke="#2AA43C"></path>
                            </svg>
                        </div>
                        <h3 className="font-bold">Join the Climate Movement</h3>
                        <p>
                            Join the community of Climate Friendly Travellers and help
                            increase the positive impact we are having on the planet. 
                            <span className="mobile-hide">
                                Together we are already making a real difference.
                            </span>
                        </p>
                    </div>
                    
                    {!(flightsImpact instanceof Error) && typeof flightsImpact === 'object' && flightsImpact !== null && (<>
                    <div className="py-6 mobile-hide translate-dynamic impact-section">

                        <div className="center bg-blue-light py-6 px-3" >
                            <h1 className="CFT-title">{passengerDistanceValue}</h1>
                            <h5 className="font-bold" >Carbon Neutral Air {passengerDistanceUnit}</h5>
                        </div>

                        <div className="mw-800px center" style={{ marginTop: "-36px" }}>
                            <div className="row text-align-start m-0">

                                <div className="col-4 d-grid">
                                    <div className="center bg-dark shadow w-100">
                                        <div className="img-cover h-120px CFT-impact-community w-100">

                                        </div>
                                        <div className="px-2 py-4 font-bold color-white">
                                            <h2 className='color-white'>{numberOfPassengersValue}</h2>
                                            <p>Climate Friendly Travelers</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4 d-grid">
                                    <div className="center bg-dark shadow w-100">
                                        <div className="img-cover h-120px CFT-impact-CO2 w-100">

                                        </div>
                                        <div className="px-2 py-4 font-bold color-white">
                                            <h2 className='color-white'>{carbonOffsetImpactValue}</h2>
                                            <p>{carbonOffsetImpactUnit} of CO2 Offset</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4 d-grid">
                                    <div className="center bg-dark shadow w-100">
                                        <div className="img-cover h-120px CFT-impact-trees w-100">

                                        </div>
                                        <div className="px-2 py-4 font-bold color-white">
                                            <h2 className='color-white'>{treeGrowthYearsValue}</h2>
                                            <p><span className="mr-2">Tree Growth Years</span>
                                                <Popover content={treeMetric} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
                                                    </svg>
                                                </Popover>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="mobile-show">
                        <div className="text-center font-bold pt-4">
                            <b className="mb-3">Together we are fighting climate change.</b>
                            <h1>{passengerDistanceValue}</h1>
                            <p>Carbon Neutral Air {passengerDistanceUnit}</p>
                        </div>
                    </div>
                    </>)}
                </div>
            </div>


            <div className="bg-green-light py-6" id="FlightCalculator">
            <div className="container mobile-p-0">
                    <div className="bg-white center shadow-lg">
                        <div id="FlightCalculatorDummy"/>
                        <FlightCalculator
                            currency={selectedCurrency}
                            onChangeCurrency={onChangeCurrency}
                            currencyList={currencyList}
                            merchantCode={merchantCode}
                            transactionMerchantCode={merchantCodeFromUrl}
                        />
                    </div>
                </div>
            </div>

            <div className="py-6 mobile-p-0">
                <div className="container text-center">
                    <div className="center mw-800px">
                        <iframe src="https://player.vimeo.com/video/755370810?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" className="w-100" height="440" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                    </div>
                </div>
            </div>

            <div className="bg-green-light pt-5 pb-1 hide-when-right-to-left-language">
                <div className="container text-center">
                    <Carousel dynamicHeight={false} showThumbs={false} showArrows={false} showStatus={false} infiniteLoop={true} autoPlay={true}>

                        <div key="1" className="p-3 mb-4">
                            <div className="p-3">
                                <div className="center mw-600px">
                                    <div className="Quote">
                                        <h4 >&apos;&apos;As a frequent travel booker for my company, I find CarbonClick easy to use to offset our travellers carbon. We know it&apos;s important to reduce the carbon footprint of our company when we travel both domestically and internationally.&apos;&apos;</h4>
                                        <p className="font-bold">Eilish</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div key="2" className="p-3 mb-4">
                            <div className="p-3">
                                <div className="center mw-600px">
                                    <div className="Quote">
                                        <h4 >&apos;&apos;Flying is an important part of my life, I fly for work, when visiting family and for holidays. I use CarbonClick to easily offset my flight emissions to help me travel responsibly.&apos;&apos;</h4>
                                        <p className="font-bold">John</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div key="3" className="p-3 mb-4">
                            <div className="p-3">
                                <div className="center mw-600px">
                                    <div className="Quote">
                                        <h4 >&apos;&apos;Everyone needs to do their part to help fight climate change, A tool like CarbonClick makes it quick and simple to remove our travel emissions without having to give up on flying.&apos;&apos;</h4>
                                        <p className="font-bold">Christopher</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Carousel>
                </div>
            </div>

            {Array.isArray(projects) &&
                <div className="py-6">
                    <div className="container text-center translate-dynamic">
                        <div className="mb-5 center mw-600px">
                            <h3 className="font-bold">Support the World’s Best Climate Projects</h3>
                            <p>
                                We source the world’s best climate projects that deliver the
                                best impact for people and our planet, and we make sure it’s
                                completely transparent. Learn more about our projects here.
                            </p>
                        </div>
                        <div className="text-align-start mobile-text-center project-list-container">
                            <ProjectsList title={null} projects={projects} lang={currentLang} />
                        </div>
                    </div>
                </div>
            }

            <div className="py-6 mobile-p-0">
                <div className="container text-center">
                    <h3 className="font-bold mb-4">Climate Friendly Traveller FAQs</h3>

                    <div className="mb-3 center text-align-start mw-800px">
                        <Accordian title={"What is a carbon offset?"} >A carbon credit is what is bought and sold when dealing with voluntary carbon offsets. For every tonne of CO2 that a project manages to absorb or otherwise reduce, a carbon credit is issued. Carbon credits are certified by international standards and held in registries like the Gold Standard registry (international credits) or the Emissions Trading Register (New Zealand).</Accordian>
                        
                        <Accordian title={"What does carbon offsetting do?"} >Carbon offsetting is a tool that allows individuals or companies to reduce their environmental impact. Purchasing carbon offsets supports environmental projects that remove greenhouse gases from the air, these projects could be things like reforestation projects or investment into renewable energy. Purchasing one carbon offset credit removes the equivalent of one tonne of CO2 from the atmosphere.</Accordian>
                        
                        <Accordian title={"How are flight emissions calculated?"}>CarbonClick uses the international standard method for flight emissions calculations. Taking into account average loading factors and routes between different airports.<span className='d-block pt-4'></span>A point of contention in calculating the emissions of flights is ‘Radiative Forcing’, this related to the effects vapours and aerosols have when released in high atmosphere in forming clouds, due to the ongoing debate in the scientific community CarbonClicks calculations do not currently include the effects of radiative forcing.</Accordian>
                        <Accordian title={"Who are CarbonClick?"} >CarbonClick is a New Zealand company that exists to reverse climate change, CarbonClick empowers businesses and their customers to tackle climate change, by making carbon offsets simple, trustworthy and cost effective. Building a happier and more sustainable future for everyone.</Accordian>
                        <Accordian title={"Where does the money go?"} >The money paid to CarbonClick for carbon credits goes towards certified carbon offset projects that have met the highest standards. The funds are distributed amongst carbon offset suppliers carrying out the projects and CarbonClick takes a margin on the carbon credits for sourcing projects, quality assurance, administration and transaction fees.</Accordian>
                    
                        <Accordian title={"How do I know the offset is trustworthy?"} >CarbonClick is committed to making carbon offsetting safe and trustworthy, we do this by tracing every transaction to the projects it supported so you can see the retirement certificates for every kg of CO2 offset. The trace link is sent in every purchase confirmation email, and can also be found on our website.</Accordian>
                        </div>

                    <a href={footerLinks.faq} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-lg font-bold" >View All</a>
                    <div className="mobile-show py-5"></div>

                </div>
            </div>


            {/*<div className="bg-green-light py-6">
                <div className="container text-center">
                    <div className="mb-5 center mw-600px">
                        <h2 className="font-light">Make a difference in fighting climate change</h2>
                        <p>Purchase a subscription to make an ongoing, traceable difference. We&apos;ll show you how your positive impacts grow each month.</p>
                    </div>

                    country && <PlansList
                        selectedPlan={selectedPlan}
                        onSelectedPlanChange={setSelectedPlan}
                        planChosen={planChosen}
                        country={country}
                        setLocationModalVisible={setShowModal}
                    />}
                </div>
            </div>*/}

            <Footer/>
            <CountrySelect isShowModal={isShowModal} setShowModal={setShowModal} country={country} setCountry={setCountry}></CountrySelect>
        </div>
    )
}


export default ClimateFriendlyTravellerPage;